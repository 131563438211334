import React, {Suspense} from "react";
import { Routes, Route, Outlet, NavLink } from 'react-router-dom';
import Footer from "../components/Footer";
import Header from "../components/Header";
import Home from "../pages/Home"
import { Styles } from "../styles/styles";
import Datenschutz from "../pages/Datenschutz";
import Impressum from "../pages/Impressum";
import Presse from "../pages/Presse";
import FaqPage from "../pages/FaqPage";
import GuteGruende from "../pages/GuteGruende";
import FlyerPage from "../pages/FlyerPage";
import KostenInfo from "../pages/KostenInfo";
import RadOMatPage from "../pages/RadOMatPage";
import VideoAntwortenPage from "../pages/VideoAntworten";
import EnglishSummary from "../pages/EnglishSummary";
import FlyerPageEn from "../pages/FlyerPageEn";
import PodcastPage from "../pages/PodcastPage";
import Newsletter from "../components/Newsletter";
import Newsletter01 from "../pages/Newsletter01";
import NewsletterAbmeldung from "../components/NewsletterAbmeldung";
import Newsletter02 from "../pages/Newsletter02";


const Router = () => {
  return (

    <Suspense fallback={null}>
      <Styles />
      <Header />
      {/* <NewsLine/> */}
      <Routes>
        <Route 
          path = "*" 
          element = {<p>There's nothing here: 404!</p>}
        />
        <Route 
          index  element = {<Home/>}
        />
        <Route 
          path = "home" 
          element = {<Home/>}
        />      
        <Route 
          path = "datenschutz" 
          element = {<Datenschutz/>}
        />
        <Route 
          path = "impressum" 
          element = {<Impressum/>}
        />
        <Route 
          path = "news" 
          element = {<Presse/>}
        />
        <Route 
          path = "faq" 
          element = {<FaqPage/>}
        />
        <Route 
          path = "gute-gruende" 
          element = {<GuteGruende/>}
        />
        <Route 
          path = "flyer" 
          element = {<FlyerPage/>}
        />
        <Route 
          path = "flyer-en" 
          element = {<FlyerPageEn/>}
        />
        <Route 
          path = "rad-o-mat" 
          element = {<RadOMatPage/>}
        />
        <Route 
          path = "kosten"
          element = {<KostenInfo/>}
        />
        <Route 
          path = "eure-fragen"
          element = {<VideoAntwortenPage/>}
        />
        <Route
          path = "podcast"
          element = {<PodcastPage/>}
        />
        <Route
          path = "en"
          element = {<EnglishSummary/>}
        />
        <Route
            path = "newsletter"
            element = {<Newsletter/>}
        />
        <Route
          path = "anmeldung-newsletter"
          element = {<Newsletter/>}
        />
        <Route
            path = "newsletter/1"
            element = {<Newsletter01/>}
        />
        <Route
            path = "newsletter/2"
            element = {<Newsletter02/>}
        />
        <Route
          path = "abmeldung-newsletter"
          element = {<NewsletterAbmeldung/>}
        />
        <Route
          path = "*" 
          element = {<p>There's nothing here: 404!</p>}
        />

      </Routes>
      <Footer />
      {/* <Button 
        onClick = {changeState}
      >Zeige Impressum und Datenschutz</Button>
      <Appendix state = {state}/> */}
    </Suspense>
  );
};

export default Router;
